/* eslint-disable @typescript-eslint/no-unused-vars */
import { first, keyBy } from 'lodash-es'
import { computed, Ref, ref } from 'vue'
import { useRequest } from 'vue-request'

import { CriteriaRowType } from '@/components/analysis/hybrid/HybridHierarchy.vue'
import {
  ChartData,
  InputNode,
  InputType,
  parseArray,
  parseNumberArray
} from '@/components/analysis/libs/common'
import { Network } from '@/libs/bayes'
import { defaultProbs } from '@/libs/common'
import { logger } from '@/libs/logger'
import { AHP_CRITERIA, APISETS } from '@/services/api/ahp/set'
import { AhpCriteria, AhpCriterion } from '@/types/database/ahp'
import { User } from '@/types/database/user'

export const OMITTED = 'OMIT'
export const ANALYSIS_API: 'v1' | 'v2' | 'v3' = 'v2' // v0, v1 and v2

export const scalingMethodOptions = [
  {
    label: 'LINEAR',
    value: 'LINEAR'
  },
  {
    label: 'LOGARITHMIC',
    value: 'LOGARITHMIC'
  },
  {
    label: 'ROOT_SQUARE',
    value: 'ROOT_SQUARE'
  },
  {
    label: 'INVERSE_LINEAR',
    value: 'INVERSE_LINEAR'
  },
  {
    label: 'BALANCED_N',
    value: 'BALANCED_N'
  },
  {
    label: 'POWER',
    value: 'POWER'
  },
  {
    label: 'GEOMETRIC',
    value: 'GEOMETRIC'
  },
  {
    label: 'ADAPTIVE',
    value: 'ADAPTIVE'
  }
]

export const eigenCalculationMethodOptions = [
  {
    label: 'EVM',
    value: 'EVM'
  },
  {
    label: 'RGMM',
    value: 'RGMM'
  }
]

export default function useHybridCriteria(
  workspaceId: string,
  currentUser: Ref<User>,
  networkMap: Ref<Record<string, Network>>,
  criteriaRows: Ref<CriteriaRowType[]>,
  refreshCallback: () => void,
  successCallback: () => void = () => undefined
): any {
  const parseRaw = (rawValue: string): any => {
    /* 
  
    networkKey,parentKey,childKey,..
    ,TOTAL,0,C1,C2,C3
    ,C1,1,C11,C12
    BN,C11,2,N111,N112
    BN,C3,1,N3
    */

    const networkByName = keyBy(networkMap.value, 'name')
    const firstNetwork = networkMap.value[Object.keys(networkMap.value)[0]]
    if (!firstNetwork) {
      return {
        error: 'You first need to upload network',
        line: 0
      }
    }
    const criteriaList: AhpCriterion[] = []
    let criterion: AhpCriterion = {}
    const criteriaMap: Record<string, any> = {}
    const rows = rawValue.split(/\r?\n/)

    const findNode = (childKey: string) => {
      let network = firstNetwork
      let nodeKey
      if (childKey.indexOf(':') !== -1) {
        const [networkName, nodeKey0] = childKey.split(':')
        nodeKey = nodeKey0 || ''
        if (networkName in networkByName) {
          network = networkByName[networkName]
        }
      } else {
        nodeKey = childKey
      }
      if (nodeKey in network.variableMapByKey) {
        return {
          nodeKey,
          nodeNetworkId: network.id,
          rawNodeKey: childKey
        }
      }
      return {}
    }

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      const cols = row.split(/\s*,\s*/)
      if (cols.length <= 1) {
        continue
      }
      const key = cols[0] || ''
      criteriaMap[key] = true
      const level = parseInt(cols[1]) || 0
      criterion = {}
      criterion.key = key
      criterion.name = key
      criterion.level = level
      criterion.isRoot = level === 0
      const childrenCandidates = cols[2]
      const { items: children } = parseArray(childrenCandidates)
      if (children.length === 1) {
        // potentially leaf criteria
        const { nodeKey, nodeNetworkId, rawNodeKey } = findNode(children[0])
        if (nodeKey) {
          criterion.type = 'node'
          criterion.nodeKey = nodeKey
          criterion.rawNodeKey = rawNodeKey
          criterion.nodeNetworkId = nodeNetworkId
          let utilityVector = [0.0, 1.0]
          const { numbers } = parseNumberArray(cols[3])
          if (!numbers) {
            return {
              error: 'Incorrect probabilities value',
              line: i
            }
          }
          utilityVector = numbers.map((number) => number)
          criterion.utilityVector = utilityVector
        } else {
          return {
            error: 'Node not found',
            line: i
          }
        }
      } else {
        criterion.type = 'virtual'
        if (cols[3]) {
          const { items } = parseArray(cols[3]) || []
          if (items) {
            criterion.userIdToWeightMap = items.reduce((acc: Record<string, number>, userId) => {
              acc[userId] = 1
              return acc
            }, {})
          }
        }
      }
      criterion.childrenKeys = children
      criteriaList.push(criterion)
    }
    return {
      criteriaList
    }
  }

  const isLoadingCriteria: Ref<boolean> = ref(false)
  const currentCriteria: Ref<AhpCriteria | undefined> = ref({
    workspaceId,
    scalingMethod: 'LINEAR',
    criteria: []
  })

  const { runAsync: runGetCriteria } = useRequest(APISETS[AHP_CRITERIA].method.gets, {
    manual: true
  })

  const onCriteriaChangeSuccess = (data: any) => {
    currentCriteria.value = data
    if (successCallback) {
      successCallback()
    }
  }

  const { runAsync: runUpdateCriteria, loading: isUpdatingCriteria } = useRequest(
    APISETS[AHP_CRITERIA].method.update,
    {
      manual: true,
      onSuccess: onCriteriaChangeSuccess
    }
  )

  const { runAsync: runCreateCriteria, loading: isCreatingCriteria } = useRequest(
    APISETS[AHP_CRITERIA].method.create,
    {
      manual: true,
      onSuccess: onCriteriaChangeSuccess
    }
  )

  const isPersistingCriteria = computed(() => isUpdatingCriteria.value || isCreatingCriteria.value)

  const normalizeCriteria = (params: any) => {
    if (criteriaRows.value?.length === 0) {
      currentCriteria.value = {
        workspaceId: workspaceId,
        criteria: [],
        ...params
      }
    } else {
      const criteria = criteriaRows.value.map((criterion: CriteriaRowType) => {
        const {
          parentKey: key,
          childrenKeys = [],
          level = 0,
          utilityVector = [],
          userIdToWeightMap = {}
        } = criterion
        const type = criterion.type || (criterion.childrenKeys?.length > 1 ? 'virtual' : 'node')
        return {
          type,
          key,
          level,
          childrenKeys,
          utilityVector,
          userIdToWeightMap
        }
      })
      currentCriteria.value = {
        ...currentCriteria.value,
        ...params,
        workspaceId: workspaceId,
        criteria
      }
    }
  }

  const denormalizeCriteria = () => {
    if (!currentCriteria.value?.criteria?.length) {
      criteriaRows.value = []
    } else {
      criteriaRows.value = currentCriteria.value.criteria.map((criterion: AhpCriterion) => {
        const {
          key: parentKey,
          type,
          level,
          childrenKeys = [],
          utilityVector = [],
          userIdToWeightMap = {}
        } = criterion
        return {
          type,
          parentKey,
          level,
          childrenKeys,
          utilityVector,
          userIdToWeightMap
        }
      })
    }
  }

  const getInputNodesFromCriteria = (): InputNode[] => {
    const inputNodes: InputNode[] = []
    if (currentCriteria.value?.criteria?.length) {
      currentCriteria.value.criteria.forEach((criterion: AhpCriterion) => {
        const { nodeKey, childrenKeys = [], nodeNetworkId } = criterion
        if (
          !nodeKey ||
          childrenKeys.length !== 1 ||
          !nodeNetworkId ||
          !networkMap.value[nodeNetworkId]
        ) {
          return
        }
        const network = networkMap.value[nodeNetworkId]
        if (!network) {
          return
        }
        const variable = network.variableMapByKey[nodeKey]
        if (!variable) {
          return
        }
        const allStates = variable.getAllStates()
        inputNodes.push({
          networkKey: network.name,
          key: nodeKey,
          values: defaultProbs(allStates.length),
          type: InputType.PROBABILITY
        })
      })
    }
    return inputNodes
  }

  const loadCriteria = async () => {
    logger.info('currentUser', currentUser.value?.username)
    if (!currentUser.value) {
      return
    }

    isLoadingCriteria.value = true
    const criteria_ = await runGetCriteria(workspaceId)
    isLoadingCriteria.value = false

    const criteria: AhpCriteria | undefined = first(criteria_ as AhpCriteria[])
    if (!criteria) {
      currentCriteria.value = {
        workspaceId: workspaceId,
        criteria: []
      }
    } else {
      currentCriteria.value = criteria
    }
    denormalizeCriteria()
  }

  const persistCriteria = async (params: any) => {
    normalizeCriteria(params)
    if (!currentCriteria.value) {
      return
    }
    if (currentCriteria.value.id) {
      currentCriteria.value = await runUpdateCriteria(
        workspaceId,
        currentCriteria.value.id,
        currentCriteria.value
      )
    } else {
      // currentCriteria.value.id = objectId()
      currentCriteria.value = await runCreateCriteria(workspaceId, currentCriteria.value)
    }
  }

  const hybridRows: Ref<any[]> = ref([])
  const chartNodeIds: Ref<any[]> = ref([])
  const chartData: Ref<ChartData> = ref([])
  const resultMap: Ref<Record<string, any>> = ref({})
  const isExecuting: Ref<boolean> = ref(false)
  const minOutputValue: Ref<number> = ref(0)
  const maxOutputValue: Ref<number> = ref(100)

  return {
    getInputNodesFromCriteria,
    currentCriteria,
    loadCriteria,
    persistCriteria,
    denormalizeCriteria,
    isPersistingCriteria,
    parseRaw
  }
}
