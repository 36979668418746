
import { IconReload, IconUserBolt } from '@tabler/icons-vue'
import { message } from 'ant-design-vue'
import moment from 'moment'
import { computed, ComputedRef, defineComponent, onMounted, ref } from 'vue'

import { DB_ENUM_VALUES, DB_FIELDS } from '@/constants/database'
import { MESSAGE } from '@/constants/message'
import { PATH } from '@/constants/router'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { UserStateEnum } from '@/store/enums/states/user'
import { User, UserNext } from '@/types'

import useHybridSurveyStatus from '../composable/hybrid-survey-status'

interface HybridStatus {
  id: string
  sentOn: string
  userId: User['id']
  surveyId: string
  status: string
  completionRate: number
  ext: Record<string, unknown> & {
    // ext.responseCompletionMap[variableId][rowId], complete status: true/false
    responseCompletionMap?: Record<string, Record<string, boolean>>
  }
  createdDate: string
  lastModifiedDate: string
  schemaVersion: number
}

const tableColumns = [
  {
    dataIndex: 'username',
    key: DB_FIELDS.SURVEY_STATUS.USER_ID,
    title: 'Username'
  },
  {
    title: 'Status',
    dataIndex: DB_FIELDS.SURVEY_STATUS.STATUS,
    key: DB_FIELDS.SURVEY_STATUS.STATUS
  },
  {
    title: 'Send On',
    dataIndex: DB_FIELDS.SURVEY_STATUS.SENT_ON,
    key: DB_FIELDS.SURVEY_STATUS.SENT_ON,
    customRender: ({ text }: { text: string }) => {
      const sendOn = moment(text)
      if (sendOn.isValid()) {
        return sendOn.format('DD/MM/YYYY HH:mm:ss')
      }
      return ''
    }
  },
  {
    title: 'Completion Rate',
    key: DB_FIELDS.SURVEY_STATUS.COMPLETION_RATE,
    dataIndex: DB_FIELDS.SURVEY_STATUS.COMPLETION_RATE,
    customRender: ({ text }: { text: number }) => `${text * 100}%`
  },
  {
    title: 'Action',
    key: 'action',
    slots: { customRender: 'action' }
  }
]

export default defineComponent({
  components: {
    // IconMail,
    IconReload,
    IconUserBolt
  },
  props: {
    workspaceId: { type: String, required: true },
    surveyId: { type: String, default: '' }
  },
  setup(props) {
    const store = useStore()

    const users: ComputedRef<UserNext[]> = computed(
      () => store.state[ModuleNames.USER][UserStateEnum.USER_LIST]?.content
    )
    const {
      dataSource,
      distributeSurvey,
      initializeSurveyStatus,
      loadSurveyStatus
    } = useHybridSurveyStatus(props.workspaceId, users)

    const selectedRowsRef = ref<Array<HybridStatus>>([])
    const selectedRowsKeysRef = ref<Array<string | number>>([])
    const isSendingEmailModalVisible = ref<boolean>(false)
    const sendingProgress = ref<number>(0)

    const rowSelection = {
      selectedRowKeys: selectedRowsKeysRef,
      onChange: (selectedRowKeys: (string | number)[], selectedRows: Array<HybridStatus>) => {
        selectedRowsRef.value = selectedRows
        selectedRowsKeysRef.value = selectedRows.map((each) => each.id)
      },
      onSelect: (record: HybridStatus, selected: boolean, selectedRows: Array<HybridStatus>) => {
        selectedRowsRef.value = selectedRows
        selectedRowsKeysRef.value = selectedRows.map((each) => each.id)
      },
      onSelectAll: (selected: boolean, selectedRows: Array<HybridStatus>) => {
        selectedRowsRef.value = selectedRows
        selectedRowsKeysRef.value = selectedRows.map((each) => each.id)
      }
    }

    /**
     * Toggle email sending modal
     **/
    const toggleEmailSendingModal = () => {
      isSendingEmailModalVisible.value = !isSendingEmailModalVisible.value
    }

    /**
     * Generate survey link
     **/
    const generateSurveyUrl = () => {
      if (props.workspaceId) {
        const paramsObj: Record<string, any> = {
          workspaceId: props.workspaceId
        }
        const matches = PATH.AHP.match(/:[a-zA-Z]+(?=\/)?/g)
        let result = PATH.AHP
        if (matches && matches.length > 0) {
          matches.forEach((each) => {
            const varName = each.replace(':', '')
            if (Object.prototype.hasOwnProperty.call(paramsObj, varName)) {
              result = result.replace(each, paramsObj[varName])
            }
          })
        }
        return `${window.location.origin}/#/go/` + result
      }
      return null
    }

    /**
     * Send survey to each user
     * @param record
     */
    const sendSurvey = async (record: HybridStatus) => {
      const { id } = record
      try {
        const surveyUrl = generateSurveyUrl()
        if (surveyUrl) {
          isSendingEmailModalVisible.value = true
          sendingProgress.value = 0
          distributeSurvey(id, surveyUrl)
          sendingProgress.value = 100
        }
        message.success(MESSAGE.SEND_SURVEY_SUCCESS)
        isSendingEmailModalVisible.value = false
      } catch (err) {
        message.error(MESSAGE.SEND_SURVEY_FAIL)
        throw err
      }
    }

    /**
     * Bulk send survey to each user
     */
    const bulkSendSurvey = async () => {
      if (selectedRowsRef.value.length > 0) {
        isSendingEmailModalVisible.value = true
        sendingProgress.value = 0
        for (let i = 0; i < selectedRowsRef.value.length; i++) {
          const { status } = selectedRowsRef.value[i]
          if (status !== DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.COMPLETED) {
            try {
              const surveyUrl = generateSurveyUrl()
              if (surveyUrl) {
                // await store.dispatch(
                //   vuexActions(ModuleNames.SURVEY_STATUS, HybridStatusActionEnum.DISTRIBUTE_SURVEY),
                //   {
                //     id,
                //     surveyUrl,
                //     surveyUrlVariableName: 'surveyUrl',
                //     userNameVariableName: 'userName',
                //     surveyNameVariableName: 'surveyName'
                //   }
                // )
                sendingProgress.value = ((i + 1) / selectedRowsRef.value.length) * 100
              }
            } catch (err) {
              message.error(MESSAGE.SEND_SURVEY_FAIL)
              throw err
            }
          }
        }
        message.success(MESSAGE.SEND_SURVEY_SUCCESS)
        selectedRowsRef.value = []
        selectedRowsKeysRef.value = []
      }
      return
    }

    const copyToClipboard = (text: string) => {
      navigator.clipboard.writeText(text)
    }

    onMounted(() => {
      loadSurveyStatus()
    })

    const initialize = async () => {
      await initializeSurveyStatus()
      loadSurveyStatus()
    }

    return {
      refresh: loadSurveyStatus,
      initialize,
      DB_ENUM_VALUES,
      dataSource,
      sendSurvey,
      bulkSendSurvey,
      copyToClipboard,
      toggleEmailSendingModal,
      tableColumns,
      users,
      rowSelection,
      selectedRowsRef,
      selectedRowsKeysRef,
      isSendingEmailModalVisible,
      sendingProgress
    }
  }
})
