import { reactive, Ref, ref, watch } from 'vue'
import { useRequest } from 'vue-request'

import { executeAhp, executeAhpMulti } from '@/services/api/ahp'
import { AhpResults } from '@/types/database/ahp'

import { CriteriaWeightsType, PairwiseRowType } from './hybrid-types'

type MapOfResults = Record<string, Record<string, AhpResults>>

const CONSOLIDATE_USER_ID = '888888888888888888888888'

export default function useHybridSelector(
  workspaceId: string,
  currentUserId: Ref<string>,
  currentResultsMap: Ref<MapOfResults>,
  isConsolidate = false,
  selectedScalingMethod: Ref<string>,
  refreshCallback: () => void
): any {
  const pairwiseRows: Ref<PairwiseRowType[]> = ref([])
  const selectedParentKey: Ref<string | undefined> = ref()
  const selectedUserId: Ref<string | undefined> = ref(currentUserId.value || CONSOLIDATE_USER_ID)
  const criteriaWeights: Ref<CriteriaWeightsType[]> = ref([])
  const childrenKeys: Ref<string[]> = ref([])
  const matrix: Ref<number[][] | undefined> = ref()
  const stats = reactive({
    maxEigenValue: 0,
    consistencyRatio: 0,
    consistencyRatioAlonsoLamata: 0,
    consensusIndicator: 0
  })

  const { run: runExecuteAhp, data: ahpDataResult } = useRequest(executeAhp, {
    manual: true
  })
  const { run: runExecuteAhpMulti } = useRequest(executeAhpMulti, {
    manual: true
  })

  const pickResults = () => {
    let results: AhpResults | undefined
    if (!selectedParentKey.value || !selectedUserId.value) {
      return
    }
    if (isConsolidate) {
      results = currentResultsMap.value[selectedUserId.value]?.[selectedParentKey.value]
    } else {
      results = currentResultsMap.value[currentUserId.value]?.[selectedParentKey.value]
    }
    return results
  }

  const refreshResults = (results: AhpResults | undefined) => {
    if (!results) {
      return
    }
    const weights = results.weights
    const inconsistencies = results.inconsistencies
    const inconsistenciesRank = results.inconsistenciesRank
    criteriaWeights.value.forEach((record, index) => {
      if (!weights[index]) {
        return
      }
      record.weight = weights[index].elicitedValue
      record.elicitedWeight = (weights[index].elicitedValue || 0) * 100
      record.calculatedWeight = weights[index].calculatedValue || 0
      record.error = weights[index].error
    })
    pairwiseRows.value.forEach((record: PairwiseRowType, index) => {
      record.inconsistency = inconsistencies[index]
      record.rank = inconsistenciesRank[index]
    })
    matrix.value = results.matrix as number[][]
    const {
      maxEigenValue = stats.maxEigenValue,
      consistencyRatio = stats.consistencyRatio,
      consistencyRatioAlonsoLamata = stats.consistencyRatioAlonsoLamata
    } = results

    Object.assign(stats, {
      maxEigenValue,
      consistencyRatio,
      consistencyRatioAlonsoLamata
    })
    if (refreshCallback) {
      refreshCallback()
    }
  }

  const syncResults = () => {
    const results: AhpResults | undefined = pickResults()
    refreshResults(results)
  }

  watch(selectedUserId, syncResults)
  watch(selectedParentKey, syncResults)
  watch(currentResultsMap, syncResults)

  const calculateAdHoc = () => {
    if (selectedUserId.value === CONSOLIDATE_USER_ID) {
      const ahpResponsesList = ['user1', 'user2'].map(() => {
        const responses = pairwiseRows.value.map(({ pair, priority }: PairwiseRowType) => ({
          criteriaPair: pair,
          priority,
          intensity: 1
        }))
        return {
          workspaceId: 'test',
          userId: 'user-1',
          parentKey: 'root',
          responses
        }
      })

      runExecuteAhpMulti(workspaceId as string, {
        ahpCriteria: {
          id: 'test',
          workspaceId: 'test',
          criteria: [
            {
              key: 'root',
              name: 'root',
              type: 'virtual',
              isRoot: true,
              level: 0,
              childrenKeys: childrenKeys.value || []
            }
          ]
        },
        ahpResponsesList,
        parentKey: 'root'
      })
    } else {
      const responses = pairwiseRows.value.map(
        ({ pair, priority, intensity }: PairwiseRowType) => ({
          criteriaPair: pair,
          priority,
          intensity: intensity
        })
      )
      runExecuteAhp(workspaceId as string, {
        scalingMethod: selectedScalingMethod.value,
        ahpCriteria: {
          id: 'test',
          workspaceId: 'test',
          criteria: [
            {
              key: 'root',
              name: 'root',
              type: 'virtual',
              isRoot: true,
              level: 0,
              childrenKeys: childrenKeys.value || []
            }
          ]
        },
        ahpResponses: {
          workspaceId: 'test',
          userId: 'user-1',
          parentKey: 'root',
          responses
        },
        parentKey: 'root'
      })
    }
  }

  watch(ahpDataResult, () => {
    if (ahpDataResult.value) {
      const weights = ahpDataResult.value.weights
      const errors = ahpDataResult.value.errors
      const inconsistencies = ahpDataResult.value.inconsistencies
      const inconsistenciesRank = ahpDataResult.value.inconsistenciesRank
      if (!weights || !errors) {
        return
      }
      criteriaWeights.value.forEach((record: CriteriaWeightsType, index) => {
        record.weight = weights[index]
        record.calculatedWeight = weights[index]
        record.elicitedWeight = weights[index] * 100
        record.error = errors[index]
      })
      pairwiseRows.value.forEach((record: PairwiseRowType, index: number) => {
        record.inconsistency = inconsistencies[index]
        record.rank = inconsistenciesRank[index]
      })
      matrix.value = ahpDataResult.value.matrix as number[][]
      stats.maxEigenValue = ahpDataResult.value.maxEigenValue
      stats.consistencyRatio = ahpDataResult.value.consistencyRatio
      stats.consistencyRatioAlonsoLamata = ahpDataResult.value.consistencyRatioAlonsoLamata
    }
  })

  return {
    selectedParentKey,
    selectedUserId,
    pairwiseRows,
    criteriaWeights,
    childrenKeys,
    calculateAdHoc,
    matrix,
    stats,
    syncResults,
    refreshResults
  }
}
