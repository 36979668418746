import { AxiosError } from 'axios'

import axios from '@/axios'
import { ENDPOINTS } from '@/constants/api'
import { errorHandler, extractResponse } from '@/services/api/utils'

/**
 * Get a network
 * @param id - network uuid
 */
const getSession = async (id: string): Promise<any> => {
  try {
    return extractResponse(await axios.get(ENDPOINTS.SESSIONS({ id })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const createSession = async (workspaceId: string): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.SESSIONS({ workspaceId })))
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const setSessionParams = async (
  workspaceId: string,
  sessionId: string,
  payload: Record<string, any>
): Promise<any> => {
  try {
    await axios.post(ENDPOINTS.SESSION_PARAMS({ workspaceId, sessionId }), payload)
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const performAnalysis = async (workspaceId: string, payload: Record<string, any>): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.ANALYSIS({ workspaceId }), payload))?.values
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const performAnalysis2 = async (
  workspaceId: string,
  payload: Record<string, any>
): Promise<any> => {
  try {
    return extractResponse(await axios.post(ENDPOINTS.ANALYSIS_EXECUTE({ workspaceId }), payload))
      ?.values
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const syncExecuteSensitivity = async (
  workspaceId: string,
  payload: Record<string, any>
): Promise<any> => {
  try {
    return extractResponse(
      await axios.post(ENDPOINTS.ANALYSIS_SENSITIVITY_EXECUTE_NEXT({ workspaceId }), payload)
    )
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const runSession = async (workspaceId: string, sessionId: string): Promise<any> => {
  try {
    await axios.post(ENDPOINTS.SESSION_RUN({ workspaceId, sessionId }), '')
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

const getSessionResults = async (
  workspaceId: string,
  sessionId: string,
  payload: Record<string, any>
): Promise<any> => {
  try {
    return extractResponse(
      await axios.post(ENDPOINTS.SESSION_RESULTS({ workspaceId, sessionId }), payload)
    )?.values
  } catch (err) {
    errorHandler(err as AxiosError)
  }
}

export {
  performAnalysis2,
  createSession,
  getSession,
  setSessionParams,
  runSession,
  performAnalysis,
  getSessionResults,
  syncExecuteSensitivity
}
