<template>
  <div class="sza-whatif-table">
    <a-table size="small" :data-source="data" :columns="columns" :pagination="false">
      <template #leftActions="{ record, index }">
        <div
          v-if="record.type === ROW_TYPE.INPUT || record.type === ROW_TYPE.OUTPUT"
          style="display: flex; flex-direction: column; cursor: pointer"
        >
          <IconCaretUp :size="10" @click="moveUp(record, index)" />
          <IconCaretDown :size="10" @click="moveDown(record, index)" />
        </div>
      </template>
      <template #name="{ record }">
        <div v-if="record.type === ROW_TYPE.LABEL" class="row-label">
          {{ record.name }}
        </div>
        <div v-else style="padding: 3px">
          {{ record.name }}
          <span v-if="record.commonNetworkKey">({{ record.commonNetworkKey }})</span>
        </div>
      </template>
      <template #value="{ text, record }">
        <div
          v-if="text && record.type === ROW_TYPE.LABEL"
          style="padding: 3px; background-color: #f0f0f0"
        >
          &nbsp;
        </div>
        <div v-if="text && record.type === ROW_TYPE.ACTIONS" class="sza-whatif-actions">
          <a-checkbox
            v-model:checked="text.task.isSelected"
            size="small"
            @change="toggleTask(text.task)"
          />
          <IconCopy :size="14" @click="duplicateTask(text.taskId, text.taskIndex)" />
          <IconTrash :size="14" @click="removeTask(text.taskId, text.taskIndex)" />
          <IconRun :size="14" @click="runTask(text.task)" />
          <a-popover trigger="hover">
            <template #content>
              <a-input v-model:value="text.task.name" @change="editTask(text.task)" />
            </template>
            <IconEdit :size="14" />
          </a-popover>
          <IconCircleFilled
            :size="14"
            :color="
              text.task.status === 'COMPLETED' || text.task.status === 'SUCCESS' ? 'green' : 'grey'
            "
          />
        </div>
        <div v-if="text && record.type === ROW_TYPE.INPUT">
          <a-select
            v-if="text.inputType === InputType.STATE"
            v-model:value="text.state"
            style="width: 100%; max-width: 200px"
            :options="text?.options || []"
          />
          <vector-input
            v-if="text.inputType === InputType.PROBABILITY && !text.omitted"
            v-model="text.values"
            :states="text.states"
          />
        </div>
        <div v-if="text && record.type === ROW_TYPE.OUTPUT && !text.omitted">
          <a-input :value="text.value" :style="cellBgStyle(text.value)" />
        </div>
        <div v-if="text && record.type === ROW_TYPE.CRITERIA && !text.omitted">
          <a-input :value="text.value" :disabled="true" :style="cellBgStyle(text.value)" />
        </div>
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import {
  IconCaretDown,
  IconCaretUp,
  IconCircleFilled,
  IconCopy,
  IconEdit,
  IconRun,
  IconTrash
} from '@tabler/icons-vue'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { computed, ComputedRef, defineComponent } from 'vue'

import VectorInput from '@/components/analysis/common/VectorInput.vue'
import { AnalysisTask, InputType, ROW_TYPE } from '@/components/analysis/libs/common'
import { genCellBgStyle } from '@/libs/theme'

export const EVENTS = {
  ON_EDIT_TASK: 'onEditTask',
  ON_DUPLICATE_TASK: 'onDuplicateTask',
  ON_REMOVE_TASK: 'onRemoveTask',
  ON_RUN_TASK: 'onRunTask',
  ON_TOGGLE_TASK: 'onToggleTask',
  ON_MOVE_UP_ROW: 'onMoveUpRow',
  ON_MOVE_DOWN_ROW: 'onMoveDownRow'
}

export default defineComponent({
  components: {
    IconEdit,
    IconCopy,
    IconRun,
    IconTrash,
    IconCaretDown,
    IconCaretUp,
    IconCircleFilled,
    VectorInput
  },
  props: {
    tasks: { type: Object, required: true },
    selections: { type: Object, required: true },
    rows: { type: Object, required: true },
    multiNetwork: { type: Boolean, required: false }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const columns: ComputedRef<ColumnProps[]> = computed(() => {
      const columns: ColumnProps[] = [
        {
          title: '',
          dataIndex: 'key',
          key: 'leftActions',
          align: 'left',
          width: 30,
          slots: {
            customRender: 'leftActions'
          }
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          align: 'left',
          customCell: () => ({
            style: {
              paddingRight: '40px !important'
            }
          }),
          slots: {
            customRender: 'name'
          }
        }
      ]
      props.tasks?.forEach((task: any) => {
        columns.push({
          title: task.name,
          dataIndex: task.id,
          align: 'center',
          key: task.id,
          slots: {
            customRender: 'value'
          },
          customHeaderCell: () => {
            return {
              'data-task-name': task.name,
              'data-task-id': task.id
            }
          }
        })
      })
      return columns
    })

    const data = computed(() => {
      return props.rows
    })

    const moveUp = (record: any, rowIndex: number) => {
      emit(EVENTS.ON_MOVE_UP_ROW, record, rowIndex)
    }

    const moveDown = (record: any, rowIndex: number) => {
      emit(EVENTS.ON_MOVE_DOWN_ROW, record, rowIndex)
    }

    const cellBgStyle = (val: number) => {
      return genCellBgStyle(val)
    }

    const update = (comp: any) => {
      comp.$forceUpdate()
    }

    const duplicateTask = (taskId: string, taskIndex: number) => {
      emit(EVENTS.ON_DUPLICATE_TASK, taskId, taskIndex)
    }

    const removeTask = (taskId: string, taskIndex: number) => {
      emit(EVENTS.ON_REMOVE_TASK, taskId, taskIndex)
    }

    const runTask = (task: AnalysisTask) => {
      emit(EVENTS.ON_RUN_TASK, task)
    }

    const toggleTask = (task: AnalysisTask) => {
      emit(EVENTS.ON_TOGGLE_TASK, task)
    }

    const editTask = (task: AnalysisTask) => {
      emit(EVENTS.ON_EDIT_TASK, task)
    }

    return {
      editTask,
      toggleTask,
      update,
      runTask,
      duplicateTask,
      removeTask,
      data,
      columns,
      cellBgStyle,
      ROW_TYPE,
      EVENTS,
      InputType,
      moveUp,
      moveDown
    }
  }
})
</script>

<style lang="stylus">
@import "../../../styles/ice-table.styl"
@import "../../../styles/commons.styl"

.sza-whatif-table
  overflow auto
  .ant-table-thead
    th
      padding 4px !important
  .ant-table-row
    > td
      padding 0 !important
    > td:first-child
      padding-left 10px !important
  .row-label
    padding: 3px
    font-weight: 700
    text-transform: uppercase
  .sza-whatif-actions
      display: flex
      justify-content: center
      flex-direction: row
      align-items: center
      padding 4px 5px !important
      .ant-checkbox-inner
        width: 14px
        height: 14px
        margin-right: 5px
      > *
        margin-left: 3px
        vertical-align: middle
</style>
