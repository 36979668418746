<template>
  <div class="sza-pairwise">
    <a-table
      size="small"
      :data-source="pairwiseRows"
      :columns="pairwiseColumns"
      :pagination="false"
      style="width: 100%"
      :row-class-name="rowClassName"
    >
      <template #firstKey="{ text: firstKey, record }">
        <div :style="cellBgStyle(0, record)" class="sz-ahp-key" @click="selectPriority(0, record)">
          {{ firstKey }}
        </div>
      </template>
      <template #secondKey="{ text: secondKey, record }">
        <div :style="cellBgStyle(1, record)" class="sz-ahp-key" @click="selectPriority(1, record)">
          {{ secondKey }}
        </div>
      </template>
      <template #intensity="{ record }">
        <a-input-number
          v-model:value="record.intensity"
          size="small"
          @change="() => intensityChange(record)"
        />
      </template>
      <template #comment="{ record }">
        <plain-comment
          :initial-comment="record.comment"
          @change="(newComment) => commentChange(record, newComment)"
        />
      </template>
      <template #inconsistency="{ record }">
        {{ isNaN(record.inconsistency) ? '' : probFormatter(record.inconsistency) }}
      </template>
      <template #rank="{ record }">
        {{ record.rank }}
      </template>
    </a-table>
  </div>
</template>

<script lang="ts">
import 'splitpanes/dist/splitpanes.css'

import { computed, defineComponent } from 'vue'

import { percentFormatter, probFormatter } from '@/libs/common'

import PlainComment from './PlainComment.vue'

export const EVENTS = {
  CHANGE: 'CHANGE'
}

export default defineComponent({
  components: {
    PlainComment
  },
  props: {
    parentKey: { type: String, default: undefined },
    pairwiseRows: { type: Object, default: undefined }
  },
  emits: Object.values(EVENTS),
  setup(props, { emit }) {
    const pairwiseColumns = computed(() => {
      return [
        {
          title: 'Criteria A',
          dataIndex: 'firstKey',
          key: 'firstKey',
          width: '100px',
          slots: {
            customRender: 'firstKey'
          }
        },
        {
          title: 'Criteria B',
          dataIndex: 'secondKey',
          width: '100px',
          key: 'secondKey',
          slots: {
            customRender: 'secondKey'
          }
        },
        {
          title: 'Intensity',
          dataIndex: 'intensity',
          width: '100px',
          key: 'intensity',
          slots: {
            customRender: 'intensity'
          }
        },
        {
          title: 'Comment',
          dataIndex: 'comment',
          key: 'comment',
          slots: {
            customRender: 'comment'
          }
        },
        {
          title: 'inconsistency',
          dataIndex: 'inconsistency',
          key: 'inconsistency',
          width: '100px',
          align: 'right',
          slots: {
            customRender: 'inconsistency'
          }
        },
        {
          title: 'rank',
          dataIndex: 'rank',
          width: '100px',
          key: 'rank',
          align: 'right',
          slots: {
            customRender: 'rank'
          }
        }
      ]
    })

    const cellBgStyle = (which: number, record: any) => {
      let backgroundColor
      if (which) {
        if (record.secondKey === record.priority) {
          backgroundColor = '#ffcc00'
        } else {
          backgroundColor = 'white'
        }
      } else {
        if (record.firstKey === record.priority) {
          backgroundColor = '#ffcc00'
        } else {
          backgroundColor = 'white'
        }
      }
      return { backgroundColor }
    }

    const selectPriority = (which: number, record: any) => {
      if (which) {
        record.priority = record.secondKey
      } else {
        record.priority = record.firstKey
      }
      emit(EVENTS.CHANGE, record)
    }

    const intensityChange = (record: any) => {
      emit(EVENTS.CHANGE, props.parentKey, record)
    }

    const commentChange = (record: any, newComment: string) => {
      record.comment = newComment
      emit(EVENTS.CHANGE, props.parentKey, record)
    }

    const rowClassName = (record: any) => (record.boundary ? 'boundary-row' : null)

    return {
      commentChange,
      intensityChange,
      rowClassName,
      selectPriority,
      cellBgStyle,
      probFormatter,
      percentFormatter,
      pairwiseColumns
    }
  }
})
</script>

<style lang="stylus">
@import "../../../styles/base.styl"
@import "../../../styles/commons.styl"

.sza-pairwise
  tr
    border-top: 1px solid transparent
  .boundary-row
    td
      border-top: 1px solid grey
  td
    position: relative;
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
</style>
