import { message } from 'ant-design-vue'
import { isEqual } from 'lodash-es'
import { computed, Ref, ref, watch } from 'vue'
import { useRequest } from 'vue-request'

import { pairwiseOf } from '@/libs/common'
import { AHP_RESPONSES, APISETS } from '@/services/api/ahp/set'
import { AhpResponse, AhpResponses } from '@/types/database/ahp'

import { PairwiseRowType, ParentRowType } from './hybrid-types'

type MapOfResponses = Record<string, Record<string, AhpResponses>>

const CONSOLIDATE_USER_ID = '888888888888888888888888'

export default function useHybridResponses(
  workspaceId: string,
  currentUserId: Ref<string>,
  selectedUserId: Ref<string>,
  criteriaMap: Ref<Record<string, any>>,
  childrenKeys: Ref<string[]>,
  selectedParentKey: Ref<string | undefined>,
  isConsolidate = false,
  parentRows: Ref<ParentRowType[]>,
  pairwiseRows: Ref<PairwiseRowType[]>,
  refreshCallback: () => void
): any {
  const isLoadingResponses: Ref<boolean> = ref(false)
  const consolidatedResponses: Ref<Record<string, AhpResponses>> = ref({})
  const currentResponsesList: Ref<AhpResponses[] | undefined> = ref([])
  const currentResponsesMap: Ref<MapOfResponses> = computed(() => {
    if (!currentResponsesList.value) {
      return {}
    }
    const map: MapOfResponses = {}
    currentResponsesList.value?.forEach((results: AhpResponses) => {
      const { userId, parentKey } = results
      if (!map[userId]) {
        map[userId] = {}
      }
      map[userId][parentKey] = results
      if (isConsolidate || userId === CONSOLIDATE_USER_ID) {
        consolidatedResponses.value[parentKey] = results
      }
    })
    return map
  })

  const { gets, create, update, calculate, consolidate } = APISETS[AHP_RESPONSES].method
  const exportResponses = APISETS[AHP_RESPONSES].method['export']
  const { runAsync: runGetResponses, data: dataResponses } = useRequest(gets, {
    manual: true
  })

  // const onResponsesChangeSuccess = (data: AhpResponses) => {
  //   const { userId, parentKey } = data
  //   if (currentResponsesMap.value?.[userId]?.[parentKey]) {
  //     currentResponsesMap.value[userId][parentKey].id = data.id
  //   }
  // }

  const { runAsync: runUpdateResponses, loading: isUpdatingResponses } = useRequest(update, {
    manual: true
  })

  const { runAsync: runExecuteResponses } = useRequest(calculate, {
    manual: true
  })

  const { runAsync: runConsolidateResponses } = useRequest(consolidate, {
    manual: true
  })

  const { runAsync: runCreateResponses, loading: isCreatingResponses } = useRequest(create, {
    manual: true
    // onSuccess: onResponsesChangeSuccess
  })

  const isPersistingResponses = computed(
    () => isUpdatingResponses.value || isCreatingResponses.value
  )

  const loadResponses = async () => {
    isLoadingResponses.value = true
    await runGetResponses(workspaceId)
  }

  const calculateResponses = async () => {
    parentRows.value?.forEach(async ({ parentKey, childrenKeys }: ParentRowType) => {
      if (!parentKey) {
        return
      }
      if (selectedUserId.value === CONSOLIDATE_USER_ID && childrenKeys.length > 1) {
        await runConsolidateResponses(workspaceId, parentKey)
      } else {
        const responses = currentResponsesMap.value?.[selectedUserId.value]?.[parentKey]
        if (responses?.id) {
          await runExecuteResponses(workspaceId, responses?.id)
        }
      }
    })
    setTimeout(() => message.success('Weights has been succesfully calculated'), 100)
  }

  const makeAhpResponses = (parentKey: string, childrenKeys: string[], userId: string) => {
    if (!parentKey || !childrenKeys) {
      return undefined
    }

    const responses: AhpResponse[] = []
    const pairs = pairwiseOf<string>(childrenKeys)
    for (let index = 0; index < pairs.length; index++) {
      const { pair } = pairs[index]
      const response: AhpResponse = {
        criteriaPair: pair,
        priority: pair[0],
        intensity: 1,
        comment: ''
      }
      responses.push(response)
    }
    const ahpResponses: AhpResponses = {
      workspaceId,
      userId,
      parentKey,
      userWeight: 1,
      responses,
      touched: false
    }
    return ahpResponses
  }

  const initResponses = () => {
    if (!currentResponsesMap.value[selectedUserId.value]) {
      currentResponsesMap.value[selectedUserId.value] = {}
    }

    parentRows.value?.forEach(async ({ parentKey, childrenKeys }: ParentRowType) => {
      if (!parentKey || !childrenKeys || childrenKeys.length == 1) {
        return
      }
      let responses: AhpResponses | undefined =
        currentResponsesMap.value[selectedUserId.value][parentKey]
      if (!responses) {
        responses = makeAhpResponses(parentKey, childrenKeys, selectedUserId.value)
        if (!responses) {
          return
        }
        currentResponsesMap.value[selectedUserId.value][parentKey] = responses
      }
    })
  }

  const storeResponses = () => {
    let ahpResponses: AhpResponses | undefined
    if (!selectedParentKey.value) {
      return
    }
    ahpResponses = currentResponsesMap.value[selectedUserId.value]?.[selectedParentKey.value]
    if (!ahpResponses) {
      ahpResponses = makeAhpResponses(
        selectedParentKey.value,
        childrenKeys.value,
        selectedUserId.value
      )
      if (!ahpResponses) {
        return
      }
      if (!currentResponsesMap.value[selectedUserId.value]) {
        currentResponsesMap.value[selectedUserId.value] = {}
      }
      currentResponsesMap.value[selectedUserId.value][selectedParentKey.value] = ahpResponses
    }
    ahpResponses.touched = true
    pairwiseRows.value.forEach(({ priority, intensity, pair, comment }, index) => {
      if (ahpResponses?.responses?.[index]) {
        ahpResponses.responses[index].priority = priority || pair?.[0] || ''
        ahpResponses.responses[index].intensity = intensity || 1
        ahpResponses.responses[index].comment = comment || ''
      }
    })
  }

  const saveResponses = async (silent = false) => {
    const currentUserResponsesMap = currentResponsesMap.value[selectedUserId.value] || {}

    parentRows.value?.forEach(async ({ parentKey, childrenKeys }: ParentRowType) => {
      if (!parentKey || !childrenKeys || childrenKeys.length == 1) {
        return
      }
      let responses: AhpResponses | undefined = currentUserResponsesMap[parentKey]
      if (!responses) {
        responses = makeAhpResponses(parentKey, childrenKeys, selectedUserId.value)
        if (!responses) {
          return
        }
      }
      if (responses.id) {
        await runUpdateResponses(workspaceId, responses.id, responses)
      } else {
        await runCreateResponses(workspaceId, responses)
      }
    })
    await loadResponses()
    if (!silent) {
      setTimeout(() => message.success('Responses saved'), 100)
    }
  }

  watch(dataResponses, () => {
    currentResponsesList.value = dataResponses.value as AhpResponses[]
    isLoadingResponses.value = false
  })

  const pickResponses = () => {
    let results: AhpResponses | undefined
    if (!selectedParentKey.value) {
      return
    }
    if (selectedUserId.value === CONSOLIDATE_USER_ID) {
      results = consolidatedResponses.value?.[selectedParentKey.value]
    } else {
      results = currentResponsesMap.value?.[selectedUserId.value]?.[selectedParentKey.value]
    }
    return results
  }

  const updatePairwise = () => {
    const pairs = pairwiseOf<string>(childrenKeys.value)
    const pairwise = []
    let lastI = -1
    for (let index = 0; index < pairs.length; index++) {
      const { i, j, pair } = pairs[index]
      const boundary = i !== lastI
      pairwise.push({
        idx: index,
        i,
        j,
        boundary,
        pair,
        key: '' + index,
        firstKey: pair[0],
        secondKey: pair[1],
        priority: pair[0],
        intensity: 1,
        comment: ''
      })
      lastI = i
    }
    pairwiseRows.value = pairwise
  }

  const updatePairwiseResponses = () => {
    const responses: AhpResponse[] | undefined = pickResponses()?.responses

    if (!responses) {
      return
    }
    pairwiseRows.value.forEach((record: PairwiseRowType, index) => {
      record.intensity = responses?.[index].intensity || 1
      record.priority = responses?.[index].priority
      record.comment = responses?.[index].comment || ''
    })
    if (refreshCallback) {
      refreshCallback()
    }
  }

  const exportHybrid = (userId: string) => {
    exportResponses(workspaceId, userId).then((res: any) => {
      const blob = new Blob([res], { type: 'application/csv' })
      saveAs(blob, 'hybrid.csv')
    })
  }

  const parseRawResponses = (rawValue: string): any => {
    /* 
  
    networkKey,parentKey,childKey,..
    ,TOTAL,0,C1,C2,C3
    ,C1,1,C11,C12
    BN,C11,2,N111,N112
    BN,C3,1,N3
    */

    const ahpResponsesForUser = currentResponsesMap.value[selectedUserId.value] || {}
    const rows = rawValue.split(/\r?\n/)
    let currentParentKey = ''
    let currentCriteria: any
    let childrenKeys: string[] = []
    const parentKeys: string[] = []
    let state = 0 // waitForParentKey
    let row

    const parseRow = (rowStr: string) => {
      return rowStr.split(/\s*,\s*/).filter((c) => c.length)
    }

    const isBlankRow = (r: string) => {
      return r.trim().startsWith(',')
    }

    const pairwises: Record<string, any> = {}
    const rowLen = rows.length
    let rowIndex = 0
    while (rowIndex < rowLen) {
      row = rows[rowIndex]
      const cols = parseRow(row)
      if (!cols.length) {
        rowIndex++
        continue
      }
      if (cols.length === 1) {
        currentParentKey = cols[0]
        if (isBlankRow(currentParentKey)) {
          rowIndex++
          continue
        }
        parentKeys.push(currentParentKey)
        currentCriteria = criteriaMap.value[currentParentKey]
        childrenKeys = currentCriteria?.childrenKeys || []
        state = 1 // wait for priority table
      }
      if (state === 0) {
        rowIndex++
        continue
      }
      let invalid = false
      const matrix = []
      // skip blank lines
      do {
        rowIndex++
        row = rows[rowIndex]
      } while (isBlankRow(row) && rowIndex < rowLen)

      for (let r = 0; r < childrenKeys.length && !invalid; r++) {
        row = rows[rowIndex]
        rowIndex++
        if (!row) {
          console.log('Empty row')
          invalid = true
          break
        }
        const cols = parseRow(row)
        if (cols[0] !== childrenKeys[r]) {
          console.log('Inconsistent data', cols[0], childrenKeys[r])
          invalid = true
          break
        }
        const intensities = cols.slice(1).map((c) => parseFloat(c))
        matrix.push(intensities)
      }
      const pairwise = []
      for (let r = 0; r < childrenKeys.length && !invalid; r++) {
        const intensities = matrix[r]
        for (let c = r + 1; c < childrenKeys.length; c++) {
          const intensity1 = intensities[c]
          const intensity2 = matrix[c][r]
          let resp
          const criteriaPair = [childrenKeys[r], childrenKeys[c]]
          if (intensity1 > intensity2) {
            resp = {
              priority: childrenKeys[r],
              intensity: intensity1,
              criteriaPair
            }
          } else {
            resp = {
              priority: childrenKeys[c],
              intensity: intensity2,
              criteriaPair
            }
          }
          pairwise.push(resp)
        }
      }
      pairwises[currentParentKey] = pairwise
      state = 0
    }
    console.log(parentKeys)
    for (let p = 0; p < parentKeys.length; p++) {
      const parentKey = parentKeys[p]
      const pairwise = pairwises[parentKey]
      console.log(parentKey, pairwise, ahpResponsesForUser[parentKey])
      if (ahpResponsesForUser[parentKey]) {
        ahpResponsesForUser[parentKey].responses?.forEach((response, index) => {
          const { intensity, priority, criteriaPair } = pairwise[index]
          if (isEqual(criteriaPair, response.criteriaPair)) {
            response.intensity = intensity
            response.priority = priority
          }
        })
        ahpResponsesForUser[parentKey].touched = true
      }
    }
    return {
      pairwises
    }
  }

  return {
    initResponses,
    parseRawResponses,
    currentResponsesMap,
    exportHybrid,
    calculateResponses,
    storeResponses,
    saveResponses,
    updatePairwise,
    updatePairwiseResponses,
    loadResponses,
    isPersistingResponses
  }
}
