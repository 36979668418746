
import { size } from 'lodash-es'
import { computed, defineComponent, PropType, reactive } from 'vue'

import { CriteriaRowType } from './HybridHierarchy.vue'

export const EVENTS = {
  SELECT: 'SELECT'
}

export default defineComponent({
  props: {
    workspaceId: { type: String, default: undefined },
    criteriaRows: { type: Array as PropType<CriteriaRowType[]>, default: undefined },
    parentRows: { type: Array as PropType<any[]>, default: undefined },
    networkMap: { type: Object, default: undefined },
    currentResponsesUserMap: { type: Object, default: undefined },
    criteriaMap: { type: Object, default: undefined },
    isMultiSelect: { type: Boolean, default: false },
    userName: { type: String, required: true }
  },
  emits: [...Object.values(EVENTS)],
  setup(props, { emit }) {
    const parentColumns = computed(() => {
      let columns = []
      columns.push({
        title: 'Criterion',
        dataIndex: 'parentKey',
        key: 'parentKey'
      })
      if (props.currentResponsesUserMap) {
        columns.push({
          title: 'State',
          dataIndex: 'parentKey',
          key: 'state',
          slots: {
            customRender: 'state'
          }
        })
      }
      columns.push({
        title: 'Sub Criteria',
        dataIndex: 'childrenKeys',
        key: 'childrenKeys',
        slots: {
          customRender: 'childrenKeys'
        }
      })
      return columns
    })
    // results = currentResultsMap.value[selectedUserId.value]?.[selectedParentKey.value]
    const state = reactive<{
      selectedRowKeys: number[]
      loading: boolean
    }>({
      selectedRowKeys: [], // Check here to configure the default column
      loading: false
    })

    const selectRow = (record: any) => {
      state.selectedRowKeys = [record.key]
      emit(EVENTS.SELECT, record.parentKey, record.childrenKeys)
    }

    const dataSource = computed(() => {
      return props.isMultiSelect
        ? props.parentRows
        : props.parentRows?.filter((r: any) => {
            // ignore leave node
            if (r?.childrenKeys?.length <= 1) {
              return false
            }
            if (
              !props.criteriaMap ||
              !props.criteriaMap[r.parentKey]?.userIdToWeightMap ||
              size(props.criteriaMap[r.parentKey]?.userIdToWeightMap) == 0
            ) {
              return true
            }
            return !!props.criteriaMap[r.parentKey]?.userIdToWeightMap[props.userName]
          })
    })

    const rowSelection = computed(() => {
      return {
        selectedRowKeys: state.selectedRowKeys,
        type: props.isMultiSelect ? 'checkbox' : 'radio',
        onChange: (selectedRowKeys: any) => {
          state.selectedRowKeys = selectedRowKeys || []
          if (props.isMultiSelect) {
            const selectedParentKeyList = state.selectedRowKeys?.map(
              (key: number) => props.parentRows?.[key]?.parentKey
            )
            const childrenKeyList = state.selectedRowKeys?.map(
              (key: number) => props.parentRows?.[key]?.childrenKeys
            )
            emit(EVENTS.SELECT, selectedParentKeyList, childrenKeyList)
          } else {
            const selectedParentKey = props.parentRows?.[selectedRowKeys[0]]?.parentKey
            const childrenKeys = props.parentRows?.[selectedRowKeys[0]]?.childrenKeys || []
            emit(EVENTS.SELECT, selectedParentKey, childrenKeys)
          }
        }
      }
    })

    const customRow = (record: any) => {
      return {
        onClick: () => {
          selectRow(record)
        }
      }
    }

    return {
      dataSource,
      customRow,
      parentColumns,
      rowSelection
    }
  }
})
