<template>
  <div :class="wrapper">
    <a-popover trigger="hover">
      <template #content>
        <div class="vect-input-container">
          <div v-for="(item, index) in items" :key="index" class="vect-input">
            <div class="state">{{ states[index]?.label }}</div>
            <a-input-number
              v-model:value="items[index]"
              :disabled="!index"
              :min="0.0"
              :max="scale"
              :step="0.05"
              @change="onChange(index)"
            />
          </div>
        </div>
      </template>
      <div style="display: inline-block">
        <span v-for="(item, index) in items" :key="index" class="sza-vect-item">
          {{ Number(item).toFixed(2) }}
        </span>
      </div>
    </a-popover>
  </div>
</template>

<script lang="ts">
import { css } from '@emotion/css'
import { sum } from 'ramda'
import { defineComponent, PropType, ref, watch } from 'vue'

import { rescale } from '@/libs/common'

const wrapper = css`
  > * {
    margin-left: 4px;
  }

  .ant-input-number {
    width: 60px;
  }
`

export default defineComponent({
  props: {
    modelValue: { type: Array as PropType<number[]>, required: true },
    states: { type: Array as PropType<any[]>, required: true },
    scale: { type: Number, default: 100 }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const items = ref(rescale(props.modelValue, props.scale))
    const onChange = () => {
      items.value[0] = Number(Number(props.scale - sum(items.value.slice(1))).toFixed(2))
      emit(
        'update:modelValue',
        items.value.map((item) => item / props.scale)
      )
      emit('change')
    }

    watch(
      () => props.modelValue,
      (newVal) => {
        items.value = rescale(newVal, props.scale)
      }
    )

    return {
      items,
      onChange,
      wrapper
    }
  }
})
</script>

<style lang="stylus">
.sza-vect-item
  display: inline-block
  background: #f0f0f0
  margin: 4px 4px 4px 0
  border-radius: 4px
  border: 0
  padding: 3px 5px

.vect-input-container
  display: flex;
  flex-direction: row;
  .vect-input
    display: flex;
    flex-direction: column;
    margin-right: 2px
    .state
      text-align: center
</style>
