
import { css } from '@emotion/css'
import { sum } from 'ramda'
import { defineComponent, PropType, ref, watch } from 'vue'

import { rescale } from '@/libs/common'

const wrapper = css`
  > * {
    margin-left: 4px;
  }

  .ant-input-number {
    width: 60px;
  }
`

export default defineComponent({
  props: {
    modelValue: { type: Array as PropType<number[]>, required: true },
    states: { type: Array as PropType<any[]>, required: true },
    scale: { type: Number, default: 100 }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const items = ref(rescale(props.modelValue, props.scale))
    const onChange = () => {
      items.value[0] = Number(Number(props.scale - sum(items.value.slice(1))).toFixed(2))
      emit(
        'update:modelValue',
        items.value.map((item) => item / props.scale)
      )
      emit('change')
    }

    watch(
      () => props.modelValue,
      (newVal) => {
        items.value = rescale(newVal, props.scale)
      }
    )

    return {
      items,
      onChange,
      wrapper
    }
  }
})
