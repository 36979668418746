import { computed, Ref, ref } from 'vue'
import { useRequest } from 'vue-request'

import { DB_ENUM_VALUES } from '@/constants/database'
import { AHP_SURVEY_STATUS, APISETS } from '@/services/api/ahp/set'
import { AhpSurveyStatus } from '@/types/database/ahp'
import { UserNext } from '@/types/database/user'

export default function useHybridSurveyStatus(workspaceId: string, users: Ref<UserNext[]>): any {
  const isLoadingSurveyStatus: Ref<boolean> = ref(false)
  const { gets, create, erase, distribute } = APISETS[AHP_SURVEY_STATUS].method

  const {
    runAsync: runGetSurveyStatus,
    data: dataSurveyStatus
  }: any & { data: AhpSurveyStatus[] } = useRequest(gets, {
    manual: true
  })

  const dataSource = computed(() => {
    if (!dataSurveyStatus.value) {
      return []
    }
    return (dataSurveyStatus.value as AhpSurveyStatus[])?.map((each: AhpSurveyStatus) => ({
      ...each,
      key: each?.id,
      url: each?.ext?.surveyUrlWithSurveyKey || undefined,
      username: users.value.find((user: UserNext) => user.id === each.userId)?.username
    }))
  })

  const { runAsync: runDeleteSurveyStatus } = useRequest(erase, {
    manual: true
  })

  const { runAsync: runDistributeSurveyStatus } = useRequest(distribute, {
    manual: true
  })

  const { runAsync: runCreateSurveyStatus, loading: isCreatingSurveyStatus } = useRequest(create, {
    manual: true
  })

  const distributeSurvey = async (id: string, surveyUrl: string) => {
    const payload = {
      surveyUrl
    }
    if (id) {
      await runDistributeSurveyStatus(workspaceId, id, payload)
      return
    }
    dataSurveyStatus.value?.forEach(async (each: AhpSurveyStatus) => {
      await runDistributeSurveyStatus(workspaceId, each.id, payload)
      // ...each,
      // key: each?.id,
      // url: each?.ext?.surveyUrlWithSurveyKey || undefined
    })
    loadSurveyStatus()
  }

  const initializeSurveyStatus = async () => {
    const promises: Promise<any>[] = []
    dataSurveyStatus.value?.forEach(async (each: AhpSurveyStatus) => {
      promises.push(runDeleteSurveyStatus(workspaceId, each.id))
      // ...each,
      // key: each?.id,
      // url: each?.ext?.surveyUrlWithSurveyKey || undefined
    })
    await Promise.all(promises)
    users.value?.forEach(async (each: UserNext) => {
      const surveyStatus = {
        userId: each.id,
        status: DB_ENUM_VALUES.SURVEY_USER_STATUS.STATUS.PENDING,
        completionRate: 0
      } as AhpSurveyStatus
      await runCreateSurveyStatus(workspaceId, surveyStatus)
    })
    await runGetSurveyStatus(workspaceId)
  }

  const isPersistingSurveyStatus = computed(() => isCreatingSurveyStatus.value)

  const loadSurveyStatus = () => {
    runGetSurveyStatus(workspaceId)
  }

  return {
    dataSource,
    distributeSurvey,
    initializeSurveyStatus,
    isPersistingSurveyStatus,
    isLoadingSurveyStatus,
    loadSurveyStatus
  }
}
